<template>
  <UiPopup
    :model-value="modelValue"
    title="Can’t create shadow leads"
    description="All selected leads include Shadow-copy. Return to table and select other leads."
    primary-button-text="Return to table"
    size="small"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="emits('update:modelValue', false)"
  >
  </UiPopup>
</template>

<script setup lang="ts">
const emits = defineEmits(['update:modelValue'])

type Props = {
  modelValue: boolean
}

withDefaults(defineProps<Props>(), {
  modelValue: false,
})
</script>

<style scoped></style>
